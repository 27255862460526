/* You can add global styles to this file, and also import other style files */
 
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


 
// @import "~@fortawesome/fontawesome-free/scss/fontawesome";
// @import "~@fortawesome/fontawesome-free/scss/solid";
// @import "~@fortawesome/fontawesome-free/scss/regular";
// @import "~@fortawesome/fontawesome-free/scss/brands";
 
 
@tailwind base;
@tailwind components;
@tailwind utilities;
 
// .bg-img {
//     position: relative;
//     background-image: url('https://f005.backblazeb2.com/file/iaddd6/background_img.png');
//     background-size: cover; 
//     width: 100%;
//     z-index: 1;
// }

// .bg-img::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(10, 5, 3, 0.6);
//     z-index: -1;
// }

 
.bg-img {
    position: relative;
    // background-image: url('https://photos.orthoquant.com/file/iaddd6/background_img.png');
    background-image: url("../src/assets/images/background_img.png");
    background-size: contain;
    // background-size: cover; 
    width: 100%;
    z-index: 1;
}
 
.bg-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 5, 3, 0.6);
    z-index: -1;
}